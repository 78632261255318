.Inicio {
  position: relative;
  .main {
    height: calc(100vh);
    position: relative;
    z-index: 1;
    width: 100%;
    // background-color: var(--secondary-color);
    @media (max-width: 992px) {
      height: 70vh !important;
      min-height: 70vh !important;
      max-height: 70vh !important;
    }
    video {
      width: 100vw;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100vh;
      z-index: -2;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
    .line-degrade {
      width: 40vw;
      position: absolute;
      bottom: 20vh;
      height: 1.1rem;
      z-index: 3;
      display: block;
    }
    #content-main {
      height: 80vh;
      position: relative;
      z-index: 3;
      padding-top: 8.3rem;
      h1 {
        font-size: 5vw;
        color: #fff;
        line-height: 0.9;
        font-weight: 200;
      }
      p {
        font-size: 1.6rem;
        color: #fff;
        font-weight: 200;
        margin-top: 2rem;
      }
    }
    iframe {
      height: 100vh;
      transform: scale(1.2);
      pointer-events: none;
      @media (max-width: 992px) {
        transform: scale(2.85);
        height: 70vh;
      }
      //   margin-top: 5vh;
      //   @media (max-width: 992px) {
      //     height: 30vh;
      //   }
    }
  }
  .fixed-bar {
    bottom: 20vh;
  }
}

@media (max-width: 992px) {
  .Inicio {
    &::before {
      display: none;
    }
    .main {
      min-height: 100vh;
      max-height: 100vh;
      width: 100% !important;
      .arrow__container {
        img {
          width: 1rem;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  main.main.home-page {
    background-image: url("../../images/bg_mobile.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #505050;
      opacity: 0.3;
      z-index: 1;
    }
    video {
      display: none;
    }
  }
}
